.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.margin4{
    margin-top: 4%;
    margin-bottom: 4%;
}

.formEqualSpacing{
    padding: 2% ;
}

.textarea.form-control{
    height: 150px !important;
}