.navStyle {
  font-size: 16px;
  font-family: sans-serif;
  /* position: sticky;
  top: 0;
  z-index: 100; */
  margin-left: auto;
  background-color: white;
}

.navStyle.sticky {
  background-color: white;
}

.togglerMenu{
  color: red;
}

.me-auto{
  float: right;
}

.navlinkColor {
  color: #34495e;
  margin-right: 6ch;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navlinkColor:hover,
.navlinkColor.active {
  color: red;
  background-color: transparent;
}

.togglerMenu {
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  background-color: transparent;
  color: transparent;
  opacity: 0.7;
}