
.serviceNav{
    font-size: 16px;
    font-family: sans-serif;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
} 

.navlinkMargin{
    color:  #34495e;
    margin-right: 10px;
}

.navlinkMargin a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.marginHorizontal{
    margin-left: 4%;
    margin-right: 4%;
}