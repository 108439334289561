.defaultContainer{
    position: relative;
}

.center {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    font-size: 38px;
    color: white;
}

/* img { 
    opacity: 0.5;
} */
