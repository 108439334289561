.cardService{
    margin-top: 20px;
    background-color: white;
    justify-content: center;
  }

.cardService:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card {
  width: 30rem; /* Set your desired width */
  height: 15rem; /* Set your desired height */
  overflow: hidden;
}

.card-text {
  height: 6rem; /* Ensure content takes full height */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}