
.centeredD {
    display: flex;
    justify-content: space-evenly;
    align-items:center;
}

.mx-10{
    margin-left: 10rem;
    margin-right: 10rem;
}

@media(max-width:1999px){
.centered-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 11%;
    padding-right: 15%

}
}
@media(min-width:2000px){
    .centered-card {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 8%;
        padding-right: 12.5%
    
    }
}
