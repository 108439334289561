/* Ensure no horizontal overflow */
body, html {
    overflow-x: hidden;
  }
  
  /* Adjustments for video container */
  .elementor-background-video-container {
    position: relative;
    width: 100%;
    background-color: #D7F4FF;
    overflow: hidden;
  }
  
  .elementor-background-video-container video {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #D7F4FF;
    opacity: 0.7;
    z-index: 0;
  }
  
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  
  .text-overlay h1 {
    font-size: 52px;
    margin-bottom: 10px;
    color: #020f1ddb;
    font-weight: 700;
    position: absolute;
    bottom: 32%;
    left: 6%;
    font-family: "Red Hat Text", Sans-serif;
  }
  
  .text-overlay p {
    font-size: 24px;
    font-weight: 400;
    font-family: "Red Hat Text", Sans-serif;
    margin: 0;
    color: #020f1ddb;
    position: absolute;
    bottom: 25%;
    left: 6%;
  }
  
  .elementor .elementor-background-video-hosted {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .elementor-background-video-hosted {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (min-width: 480px) {
    .elementor-background-video-container {
      height: 550px !important;
    }
  }
  
  @media (max-width: 480px) {
    .elementor-background-video-container {
      height: 700px !important;
    }
  }
  