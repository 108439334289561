.my-card {
    background-color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    padding: 3%;
  }
  
  .my-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  }
  
  .text-black {
    color: rgb(70 52 52) !important;
  }
  .card-title {
    color: rgb(70 52 52) !important;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    max-height: 3rem;
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }