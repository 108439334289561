.footerDiv {
    background-color: #1c1c23;
    color: white;
    /* padding-bottom: 20px; */
}

.footerDiv .row.equalSpace {
    justify-content: space-between;
    margin-left: 60px; /* Adjust this value as needed */
    margin-right: -160px;
}

.equalSpacing {
    justify-content: space-between;
}

.footerDiv .equalSpacing > .row {
    margin-left: 33px; /* Adjust this value as needed */
}

.footerTitle {
    font-size: 16px;
    font-weight: bold;
    color: #ababab;
}

.footerFont {
    color: #ababab;
    font-size: 14px;
    font-family: sans-serif;
}

.footerFont a {
    color: white;
    text-decoration: none;

}

.p-5 {
    padding-bottom: 0px;
}

.footerFont a:hover {
    color: #ababab;
    text-decoration: none;
}

.icon {
    margin-right: 5px;
    font-size: 18px;
}

.hr-divider {
    opacity: 0;
    margin: 1rem 0;
}

.equalSpacingCol {
    justify-content: space-between;
    display: flex;
}

.go-to-top-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: transparent;
    border: none;
    color: red;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}